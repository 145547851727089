const React = require('react');
const { bool, string } = require('prop-types');
const Snackbar = require('@andes/snackbar');
const { validateSnackbarColor } = require('../../../../utils/validators');

const SnackBarError = ({ key, message, show, type }) => (
  <Snackbar show={show} message={message} delay={2000} color={validateSnackbarColor(type)} key={key} />
);

SnackBarError.propTypes = {
  key: string,
  message: string.isRequired,
  show: bool,
  type: string,
};

SnackBarError.defaultProps = {
  key: null,
  show: false,
  type: 'default',
};

module.exports = React.memo(SnackBarError);
