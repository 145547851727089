const { sanatizeHighlights } = require('../../../components/qadb/lib');
const TYPES = require('../types');
const { toI18n } = require('../utils');

const ELLIPSIS_POINTS = 3;
const ELLIPSIS_STR = '...';

/**
 * isURL
 * ________________
 * Dependiendo el tipo pasado por parametro, retorna 'true' si es una url.
 */

const isURL = type => type === TYPES.ACTION;

/**
 * isHighlighted
 * ________________
 * Dependiendo el tipo pasado por parametro, retorna 'true' si es un highlight.
 */

const isHighlighted = type => type === TYPES.HIGHLIGHTED;

/**
 * filterEmpty
 * ________________
 * Solo deja aquellos elementos que contengan valor y aparta los string vacios.
 */

const filterEmpty = e => (isURL(e.type) || isHighlighted(e.type) ? e.label?.text : e?.str);

/**
 * deleteEllipis
 * ________________
 * Elimina los puntos suspensivos finales del string y el retorna el string recortado.
 */

const deleteEllipis = str => str.slice(0, -ELLIPSIS_POINTS);

/**
 * applyHighlights
 * ________________
 * Dado un elemento (texto o url), la posición del mismo en el texto original (offset) y los highlights;
 * se subdivide el elemento en partes diferenciando cual esta resaltada.
 * Si fuese una url, las partes quedan contenidas dentro un wrapper.
 * Si fuese un texto, las partes del mismo se juntan con el resto de los elementos.
 */

const applyHighlights = (offset, data, highlights) => {
  const { type, str, label, cutted } = data;
  const urlText = isURL(type) && label.text;
  const currentText = urlText || str;

  const { list } = highlights.reduce(
    (acc, { start_at, characters_amount, id }, index, highlightList) => {
      const { restText, list: accList } = acc;

      const isLastHighlight = index === highlightList?.length - 1;
      const extraOffset = currentText?.length - restText?.length;
      const totalOffset = offset + extraOffset;

      const hgt = {
        start_at: start_at - totalOffset,
        end_at: start_at - totalOffset + characters_amount - 1,
      };

      const highlightedWord = restText.slice(hgt.start_at, hgt.end_at + 1);
      const isHighlightCutted = hgt.end_at + 1 > restText?.length - ELLIPSIS_POINTS;
      const endsWithEllipis = highlightedWord.endsWith(ELLIPSIS_STR);

      // cutted: Indica si el texto o url (currentText) se encuentra cortado.
      // isHighlightCutted: Indica si la palabra resaltada quedo cortada con puntos suspensivos.
      // endsWithEllipis: Indica si la porción resaltada termina con los puntos suspensivos.

      const isCutted = cutted && isHighlightCutted && endsWithEllipis;

      /* "|----text(a)----| |----highlighted(b)----| |----text(c)----|" */

      const a = { type: TYPES.TEXT, str: restText.slice(0, hgt.start_at) };
      const b = {
        type: TYPES.HIGHLIGHTED,
        str: `{${id}}`,
        label: { text: isCutted ? deleteEllipis(highlightedWord) : highlightedWord },
        id,
      };
      const c = isCutted ? { type: TYPES.TEXT, str: ELLIPSIS_STR } : {};
      const d = isLastHighlight && { type: TYPES.TEXT, str: restText.slice(hgt.end_at + 1, restText.length) };
      const items = [a, b, c, d].filter(filterEmpty);

      return { restText: restText.slice(hgt.end_at + 1, restText.length), list: [...accList, ...items] };
    },
    { restText: currentText, list: [] },
  );

  if (isURL(type)) {
    return [{ ...data, i18nChildren: toI18n(list) }];
  }

  return list;
};

/**
 * addHighlightId
 * ________________
 * Se le agrega un identificador al highlight para que adopte el formato de -value- de i18n.jsx
 */

const addHighlightId = highlight => ({
  ...highlight,
  id: `highlighted${highlight.start_at}`,
});

/**
 * filterHighlights
 * ________________
 * Dado un rango y highlights, filtro aquellos highlights que apliquen al rango del texto.
 * Ejemplo: Si tengo string como este: "hola", un highlight con un start_at igual a 10 no es valido porque el texto solo tiene 4 caracteres.
 * Por ende no se puede resaltar una parte que existe.
 */

const filterHighlights = (str, highlights) =>
  // str: Es un objeto con las posición inicial y final de un texto.
  highlights.reduce(
    (acc, currentHighlight) => {
      const { selected, rest: accRest } = acc;

      // Si tengo highlights restantes acumulados, significa que ya no quedan por seleccionar.
      // Por ende corto el flujo retornando el current dentro del *rest*
      if (accRest?.length > 0) {
        return { ...acc, rest: [...accRest, addHighlightId(currentHighlight)] };
      }

      const { characters_amount, start_at } = currentHighlight;
      const hgt = {
        start_at,
        end_at: start_at + characters_amount - 1,
      };

      const isValid = hgt.start_at >= str.start_at && hgt.start_at <= str.end_at;
      const highlightedExceded = hgt.end_at - str.end_at;

      // Si es válido, lo guardo en *selected*. Aún siendo válido, si hubiera excedente lo guardo en *rest*
      if (isValid) {
        return {
          selected: [
            ...selected,
            addHighlightId({
              ...currentHighlight,
              characters_amount: highlightedExceded > 0 ? characters_amount - highlightedExceded : characters_amount,
            }),
          ],
          rest:
            highlightedExceded > 0
              ? [addHighlightId({ start_at: str.end_at + 1, characters_amount: highlightedExceded })]
              : [],
        };
      }

      // Si queda totalmente fuera del rango, acumuló el highlight en *rest*
      return {
        ...acc,
        rest: [addHighlightId(currentHighlight)],
      };
    },
    { selected: [], rest: [] },
  );
/**
 * highlight
 * ________________
 * Dado los highlights y un arreglo de objetos se lo subdivide en más partes, contemplando los highlights (resaltados).
 *
 * Se analiza elemento x elemento, usando los highlights acordes al rango de cada uno.
 *
 * Las urls tienen un manejo especial, ya que no pueden dividir directamente. Las partes de la misma quedan dentro de un wrapper.
 *
 * Ejemplo de resultado:
 * Array original:  [                {texto1},                                         {url1},                                {texto2}]
 * Array retornado: [{texto1a}, {highlighted1}, {texto1b},    {children: [{textoUrl2a}, {highlighted2}, {textoUrl2b}]},       {texto2}]
 */

const highlight = highlightsRaw => arrayModel => {
  const highlights = sanatizeHighlights(highlightsRaw);

  return arrayModel.reduce(
    (acc, current) => {
      const { str, originalUrl } = current;
      const { modifiedArray, restHighlights: accRestHighlights, charCounter: accCharCounter } = acc;
      const currentText = originalUrl || str;

      // Posiciones del string (texto o url)
      const start_at = accCharCounter;
      const end_at = start_at + currentText.length - 1;

      // Cantidad de caracteres recorridos
      const currentCharCounter = end_at + 1;

      // Selecciono los highlights que aplican para esta parte
      const filteredHighlights = filterHighlights({ start_at, end_at }, accRestHighlights);
      const { selected: selectedHighlights, rest: restHighlights } = filteredHighlights;

      // Si no tuviese resaltado, lo vuelvo a retornar como estaba originalmente.
      // Pero si hay resaltados, entonces subdivido el elemento separando las partes resaltadas.
      return {
        ...acc,
        charCounter: currentCharCounter,
        modifiedArray: [
          ...modifiedArray,
          ...(selectedHighlights?.length > 0 ? applyHighlights(start_at, current, selectedHighlights) : [current]),
        ],
        restHighlights,
      };
    },
    { modifiedArray: [], restHighlights: highlights, charCounter: 0 },
  ).modifiedArray;
};

module.exports = highlight;
