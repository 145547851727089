const React = require('react');
const { string, arrayOf, shape, oneOfType, node, func } = require('prop-types');
const { Label } = require('../../prop-types');
const BoxListItem = require('./box-list-item');
const IconFactory = require('../icons/factory-builder')();

const namespace = 'ui-vpp-box-list';

const BoxList = ({ content_rows }) => (
  <ul className={namespace}>
    {content_rows.map(value => (
      <BoxListItem
        className={`${namespace}__label`}
        key={(value.label || value.title).text}
        figure={IconFactory(value.icon)}
        {...value}
      />
    ))}
  </ul>
);

BoxList.propTypes = {
  content_rows: arrayOf(
    shape({
      label: Label,
      title: Label,
      icon: string,
      figure: oneOfType([node, func]),
    }),
  ).isRequired,
};

module.exports = BoxList;
