const React = require('react');
const { string, shape } = require('prop-types');
const classnames = require('classnames');
const StyledLabel = require('../../../styled-label');
const ImageComponent = require('../../../image/image-component');
const { hyphenatedbem } = require('../../../../lib/hyphenatedbem');

const namespace = 'ui-vpp-onboarding-header';

const OnboardingHeader = ({ id, title, image }) => (
  <div className={classnames(namespace, id)}>
    <StyledLabel {...title} />
    <ImageComponent className={hyphenatedbem(id)} {...image} />
  </div>
);

OnboardingHeader.propTypes = {
  id: string,
  title: string,
  image: shape({}),
};

OnboardingHeader.defaultProps = {
  id: null,
  title: null,
  image: null,
};

module.exports = OnboardingHeader;
