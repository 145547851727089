const React = require('react');
const { string, arrayOf, shape, bool } = require('prop-types');
const classnames = require('classnames');

const { useState, useEffect, useRef, useContext } = React;
const Modal = require('@andes/modal');
const ComponentList = require('../component-list');
const StaticPropsContext = require('../context/static-props');
const { hyphenatedbem } = require('../../lib/hyphenatedbem');
const { trackEvent } = require('../../lib/tracking');
const withMappedProps = require('../../utils/withMappedProps');
const useViewCounter = require('../../hooks/use-view-counter');
const {
  usePushNotificationsPermission,
  blockPushNotifications,
} = require('../../hooks/use-push-notifications-permission');
const {
  isPushNotificationsPermissionModal,
  PUSH_NOTIFICATION_DECLINE_ACTION,
} = require('../../lib/push-notifications-permission');

// AvailableComponents
const ImageComponent = require('../image/image-component');
const { imageToProps } = require('../image/image-to-props');
const StyledLabel = require('../styled-label/index');
const labelToProps = require('../styled-label/label-to-props');
const Separator = require('../grid/separator');
const { emptySeparatorToProps } = require('../grid/separator/jsonToProps');
const Media = require('../media/media');
const { mediaToProps } = require('../media/media-to-props');
const Actions = require('../buybox/actions/actions');
const OnboardingHeader = require('./components/header');
const { headerToProps } = require('./components/header/jsonToProps');
const BoxList = require('../box-list/box-list');

const namespace = 'onboarding';

const availableComponents = {};
availableComponents.modal_header = withMappedProps(OnboardingHeader)(headerToProps);
availableComponents.image_component = withMappedProps(ImageComponent)(imageToProps);
availableComponents.label_component = withMappedProps(StyledLabel)(labelToProps);
availableComponents.separator = withMappedProps(Separator)(emptySeparatorToProps);
availableComponents.generic_info_row = withMappedProps(Media)(mediaToProps);
availableComponents.label_list_component = BoxList;

const Onboarding = ({ id, components, track, storable, state, closable, ariaLabel }) => {
  const { siteId } = useContext(StaticPropsContext);
  const [isVisible, setVisibility] = useState(state !== 'TRACK_HIDDEN');
  const initialState = { components };
  const modalRef = useRef(null);
  const updateCloseStatus = useViewCounter({
    id,
    visible: state === 'TRACK_HIDDEN' || state === 'VISIBLE' || isVisible,
    storable,
    cookieName: 'modal-configuration',
  });

  useEffect(() => {
    if (state === 'VISIBLE') {
      setVisibility(true);
    }
  }, [state]);

  const canAskForPermission = usePushNotificationsPermission();

  if (isPushNotificationsPermissionModal(id) && !canAskForPermission) {
    return null;
  }

  const handleClose = (e, action) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (track) {
      trackEvent(track);
    } // Tracks on close/print for most onboardings

    if (action && action.target) {
      if (!closable) {
        setVisibility(false);
      }
      window.location.href = action.target;
    } else {
      if (storable) {
        updateCloseStatus();
      }

      if (isPushNotificationsPermissionModal(id) && action && action.id === PUSH_NOTIFICATION_DECLINE_ACTION) {
        blockPushNotifications();
      }
    }

    setVisibility(false);
  };

  const actionsToProps = props => ({
    ...props,
    actions: props.actions.map(action => ({ ...action, onClick: e => handleClose(e, action) })),
  });

  availableComponents.modal_actions = withMappedProps(Actions)(actionsToProps);

  return (
    <div key={id} className={namespace}>
      <Modal
        ref={modalRef}
        className={classnames(`${namespace}__modal`, hyphenatedbem(id), {
          [`cbt-modal--short`]: id === 'cbt_modal' && siteId === 'MLB',
        })}
        open={isVisible}
        type="card"
        srLabel={ariaLabel}
      >
        <form className={`${namespace}__container`}>
          <ComponentList availableComponents={availableComponents} initialState={initialState} hasContainerTop />
        </form>
      </Modal>
    </div>
  );
};

Onboarding.propTypes = {
  id: string.isRequired,
  components: arrayOf(shape({})).isRequired,
  track: shape({}),
  storable: bool,
  state: string,
  actions: arrayOf(shape({})).isRequired,
  closable: bool.isRequired,
  ariaLabel: string,
};

Onboarding.defaultProps = {
  track: null,
  storable: true,
  state: '',
  ariaLabel: '',
};

module.exports = React.memo(Onboarding);
