const React = require('react');
const { bool, string, arrayOf, shape, func } = require('prop-types');
const { useContext, useState, useMemo, useEffect } = require('react');
const classNames = require('classnames');
const StyledLabel = require('../styled-label');
const messageSeeMore = require('../../lib/get-see-more-message');
const StaticPropsContext = require('../context/static-props');
const maxCharsDevice = require('../../utils/max-chars-device');
const addEllipsisEnd = require('../../utils/addEllipsisEnd');
const { getHighlightedText } = require('../qadb/lib');
const TL = require('../../utils/TapeablesLinks');
const componentEnhance = require('../../lib/component-enhance');

const namespace = 'ui-pdp';
const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
};

const QuestionsTextCollapse = ({
  className,
  text: plainText,
  color,
  component,
  withDangerous,
  tapeableLinks,
  highlights,
  onClickQuestionsAi,
}) => {
  const { siteId, deviceType } = useContext(StaticPropsContext);
  const MAX_CHARS = maxCharsDevice(deviceType, component);
  const mustUseTapeableLinks = Object.keys(tapeableLinks)?.length > 0;

  const { arrayModel, charsAmount } = useMemo(
    () =>
      mustUseTapeableLinks
        ? TL.getRawData({
            plainText,
            ...tapeableLinks,
            actionConfig: deviceType === DEVICES.DESKTOP ? { html_target: '_blank' } : {},
          })
        : {},
    [deviceType, mustUseTapeableLinks, plainText, tapeableLinks],
  );

  const [isCollapsed, setCollapsedState] = useState(() => {
    const chars = mustUseTapeableLinks ? charsAmount : plainText.length;
    return chars > MAX_CHARS;
  });

  const textToShow = useMemo(() => {
    if (mustUseTapeableLinks) {
      const { text, values } = TL.getI18nValues({
        maxChars: isCollapsed && MAX_CHARS,
        highlights,
        arrayModel,
      });

      return componentEnhance.jsx(text, values);
    }

    const list = getHighlightedText(plainText, highlights);
    const highlighted = list?.length > 0 && list;
    const colappsedText = addEllipsisEnd(plainText.substring(0, MAX_CHARS));

    return isCollapsed ? colappsedText : highlighted || plainText;
  }, [MAX_CHARS, arrayModel, highlights, isCollapsed, plainText, mustUseTapeableLinks]);

  useEffect(() => {
    if (onClickQuestionsAi && !isCollapsed) {
      onClickQuestionsAi();
    }
  }, [isCollapsed, onClickQuestionsAi]);

  return (
    <div
      className={classNames(
        { [`${namespace}-${component}__questions-list__container-answer__isCollpased`]: isCollapsed },
        { [`${namespace}-${component}__questions-list__container-answer__isNoCollapsed`]: !isCollapsed },
      )}
    >
      <StyledLabel
        className={classNames(`${namespace}-${component}__questions-list__answer-item__${className}`, {
          [`${namespace}-${component}__questions-list__answer-item__with-tapeables-links`]: mustUseTapeableLinks,
        })}
        as="span"
        font_size="SMALL"
        font_family="REGULAR"
        withDangerous={withDangerous}
        text={textToShow}
        color={color}
      />
      {isCollapsed && (
        <StyledLabel
          as="a"
          color="BLUE"
          font_size="XSMALL"
          font_family="REGULAR"
          className="questions-list__see-more-button"
          text={messageSeeMore(siteId)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setCollapsedState(false);
          }}
        />
      )}
    </div>
  );
};

QuestionsTextCollapse.propTypes = {
  className: string,
  component: string.isRequired,
  color: string,
  text: string.isRequired,
  withDangerous: bool,
  highlights: arrayOf(),
  tapeableLinks: shape(),
  onClickQuestionsAi: func,
};

QuestionsTextCollapse.defaultProps = {
  className: null,
  color: 'GRAY',
  withDangerous: false,
  tapeableLinks: {},
  onClickQuestionsAi: null,
};

module.exports = QuestionsTextCollapse;
