const { stringIncludes } = require('../../lib/includes');

const isShippingCalculator = target =>
  stringIncludes(target, 'new_version=true') && stringIncludes(target, 'gz/shipping-calculator');

const getShippingCalculatorProps = target => {
  if (!isShippingCalculator(target)) {
    return null;
  }
  return {
    controlModalHeader: true,
    showCloseButton: true,
  };
};

module.exports = {
  isShippingCalculator,
  getShippingCalculatorProps,
};
