const TYPES = require('../types');
const addEllipsisEnd = require('../../addEllipsisEnd');

/**
 * cutText
 * ________________
 * Dado un texto y la cantidad de caracteres excedidos, se retorna el nuevo texto recortado.
 */

const cutText = (text, exceded) => {
  const textCutted = text.slice(0, text?.length - exceded);
  return addEllipsisEnd(textCutted);
};

/**
 * cut
 * ________________
 * Dado una cantidad máxima de carácteres y un array de objetos, se retorna un arreglo recortado y "posiblemente" con uno de sus elementos editado.
 */

const cut = charsMax => arrayModel =>
  arrayModel.reduce(
    (acc, current) => {
      const { type, str, ...urlProperties } = current;
      const { charCounter: accCharCounter, finish, modifiedArray } = acc;
      const { id, originalUrl, ...urlData } = urlProperties;

      // Si el bandera de finish esta en true, significa que el elemento recorrido quedó excluido.
      if (finish) {
        return acc;
      }

      const isURL = type === TYPES.ACTION;
      const urlText = isURL && urlData.label.text;
      const currentText = isURL ? urlText : str;

      const start_at = accCharCounter;
      const end_at = start_at + currentText.length - 1;

      const currentCharCounter = end_at + 1;
      const charsExceded = -(charsMax - currentCharCounter);

      // Caso 1: El texto esta dentro del limite de carácteres, por lo que se lo incluye.
      if (charsExceded <= 0) {
        return {
          ...acc,
          charCounter: currentCharCounter,
          modifiedArray: [...modifiedArray, current],
        };
      }

      // Caso 2: Un parte del texto sobrepasa el limite de carácteres, por lo que se lo incluye, pero editado/recortado.
      // En este caso, hay que diferenciar el manejo de un texto plano vs una url (que recorta solo el link visible, no el href).
      if (charsExceded < currentText?.length) {
        const commons = {
          charCounter: currentCharCounter - charsExceded,
          finish: true,
        };

        if (isURL) {
          return {
            ...commons,
            modifiedArray: [
              ...modifiedArray,
              {
                ...current,
                label: { text: cutText(urlText, charsExceded) },
                cutted: true,
              },
            ],
          };
        }
        return {
          ...commons,
          modifiedArray: [
            ...modifiedArray,
            {
              ...current,
              str: cutText(currentText, charsExceded),
              cutted: true,
            },
          ],
        };
      }

      // Caso 3: Todo el texto sobrepasa el limite de carácteres, por lo que "no" se lo incluye. En reemplazo se agregan los '...'.
      return {
        finish: true,
        modifiedArray: [
          ...modifiedArray,
          {
            ...current,
            str: `...`,
          },
        ],
      };
    },
    { modifiedArray: [], charCounter: 0, finish: false },
  ).modifiedArray;

module.exports = cut;
