const React = require('react');
const { string, shape, func, bool, node, oneOfType } = require('prop-types');
const { useState, useContext } = require('react');
const Button = require('@andes/button');

const StaticPropsContext = require('../../../context/static-props');
const BaseModal = require('../../../modals/base-modal');
const StyledLabel = require('../../../styled-label');
const QuestionModal = require('../question-modal');
const { trackEvent } = require('../../../../lib/tracking');
const componentEnhance = require('../../../../lib/component-enhance');

const namespace = 'ui-pdp-qadb__smart-answer-modal';

const SmartAnswerModal = ({
  key,
  title,
  item_id,
  product_id,
  ok_action,
  ask_action,
  description,
  question_modal,
  smartAnswerModal,
  onCreateQuestion,
  onCancelSearchQuery,
  onCloseSmartAnswerModal,
  onOpenQuestionsFromSmartAnswer,
  app,
  id,
  parameters,
}) => {
  const [isOpen] = useState(smartAnswerModal.isOpen);
  const [isAsking] = useState(smartAnswerModal.isAsking);
  const { deviceType } = useContext(StaticPropsContext);
  const type = deviceType === 'mobile' ? 'card' : 'large';
  const hasTitle = title && title.text;
  const titleText = hasTitle ? componentEnhance.jsx(title.text, title.values) : ' '; // Hack to show close button
  const actionType = ok_action || ask_action;
  const closeModalLabel = actionType && actionType.close_modal_label;
  let labelClassnames = 'with-one-btn';
  let fullWidthButton = true;

  const handleClose = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    trackEvent(ok_action.track);
    onCloseSmartAnswerModal();
  };

  const handleAskQuestion = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    trackEvent(ask_action.track);
    onOpenQuestionsFromSmartAnswer();
  };

  const actions = {
    primary: (
      <Button
        data-testid="smart-answer-ok-cta"
        className={`${namespace}__button ok-cta`}
        modifier="filled"
        onClick={handleClose}
      >
        {ok_action.label.text}
      </Button>
    ),
  };

  if (ask_action) {
    labelClassnames = 'with-two-btn';
    fullWidthButton = false;
    actions.secondary = (
      <Button
        data-testid="smart-answer-mq-cta"
        className={`${namespace}__button`}
        hierarchy="quiet"
        onClick={handleAskQuestion}
      >
        {ask_action.label.text}
      </Button>
    );
  }

  let text = '';

  if (typeof description === 'string') {
    text = description;
  } else {
    text = componentEnhance.jsx(description.text, description.values);
  }

  return (
    <div className={namespace}>
      <BaseModal
        key={key}
        id={`${namespace}__modal`}
        className={{ [`${namespace}__modal--without-title`]: !hasTitle }}
        isOpen={isOpen}
        onClose={handleClose}
        showCloseButton
        closable
        closeModalLabel={closeModalLabel}
        type={type}
        actions={deviceType === 'desktop' ? actions : ''}
        autoHeight={false}
        title={titleText}
      >
        <StyledLabel font_size="SMALL" className={`${namespace}__label ${labelClassnames}`} text={text} as="p" />

        {deviceType === 'mobile' && (
          <div className="andes-modal__actions">
            <Button
              data-testid="smart-answer-ok-cta"
              className={`${namespace}__button`}
              modifier="filled"
              fullWidth={fullWidthButton}
              onClick={handleClose}
            >
              {ok_action.label.text}
            </Button>
            {ask_action && (
              <Button
                data-testid="smart-answer-mq-cta"
                className={`${namespace}__button`}
                hierarchy="quiet"
                onClick={handleAskQuestion}
              >
                {ask_action.label.text}
              </Button>
            )}
          </div>
        )}
      </BaseModal>
      {isAsking && (
        <QuestionModal
          key="question-modal"
          {...question_modal}
          item_id={item_id}
          product_id={product_id}
          onCreateQuestion={onCreateQuestion}
          app={app}
          id={id}
          parameters={parameters}
          onCancelSearchQuery={onCancelSearchQuery}
        />
      )}
    </div>
  );
};

SmartAnswerModal.propTypes = {
  app: string,
  id: string.isRequired,
  title: shape({}),
  item_id: string,
  description: oneOfType([string, node]),
  ok_action: shape({
    label: shape({
      text: string.isRequired,
    }),
  }).isRequired,
  ask_action: shape({
    label: shape({
      text: string.isRequired,
    }),
  }),
  question_modal: shape({
    label: shape({
      text: string.isRequired,
    }),
  }),
  smartAnswerModal: shape({
    isOpen: bool,
    isAsking: bool,
  }),
  parameters: shape({}),
  onCreateQuestion: func.isRequired,
  onCancelSearchQuery: func,
  onCloseSmartAnswerModal: func,
  onOpenQuestionsFromSmartAnswer: func,
  key: string.isRequired,
  product_id: string.isRequired,
};

SmartAnswerModal.defaultProps = {
  app: null,
  title: null,
  item_id: null,
  ask_action: null,
  description: null,
  question_modal: null,
  smartAnswerModal: {
    isOpen: false,
    isAsking: false,
  },
  onCancelSearchQuery: null,
  onCloseSmartAnswerModal: null,
  onOpenQuestionsFromSmartAnswer: null,
  parameters: null,
};

module.exports = SmartAnswerModal;
