const React = require('react');
const { string, shape, arrayOf, bool, func } = require('prop-types');
const { useContext } = require('react');
const StaticPropsContext = require('../../../context/static-props');
const StyledLabel = require('../../../styled-label');
const componentEnhance = require('../../../../lib/component-enhance');

const namespace = 'ui-pdp-qadb__smart-answer';

const Feedback = ({ feedback, showFeedbackMessage, onLeaveFeedback }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClickAction = (e, track, __) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onLeaveFeedback(track);
  };

  return (
    <div className={`${namespace}__feedback`}>
      {showFeedbackMessage && (
        <StyledLabel
          data-testid="feedback-message"
          as="p"
          color="GREEN"
          font_family="SEMIBOLD"
          font_size="XSMALL"
          text={feedback.feedback_message}
        />
      )}
      {!showFeedbackMessage && (
        <>
          <StyledLabel as="p" color="GRAY" font_family="REGULAR" font_size="XSMALL" text={feedback.text} />
          {feedback.actions &&
            feedback.actions.map(action => (
              <StyledLabel
                key={`feedback-${action.text.toLowerCase()}`}
                data-testid={`feedback-action-${action.text.toLowerCase()}`}
                className={`${namespace}__feedback__action`}
                font_size="XSMALL"
                font_family="REGULAR"
                color="BLUE"
                as="span"
                text={action.text}
                onClick={e => handleClickAction(e, action.track, action.body)}
              />
            ))}
        </>
      )}
    </div>
  );
};

Feedback.propTypes = {
  feedback: shape({
    text: string.isRequired,
    feedback_message: string.isRequired,
    actions: arrayOf(
      shape({
        text: string.isRequired,
        track: shape({}).isRequired,
        body: shape({}).isRequired,
      }),
    ),
  }).isRequired,
  showFeedbackMessage: bool,
  onLeaveFeedback: func.isRequired,
};

Feedback.defaultProps = {
  showFeedbackMessage: false,
};

// @TODO: Eliminar values cuando subamos el middle end
const SmartAnswer = ({ title, feedback, showFeedbackMessage, onLeaveFeedback }) => {
  const { deviceType } = useContext(StaticPropsContext);
  // Eliminar cuando subamos el middle
  let titleText = '';

  if (typeof title === 'string') {
    titleText = title;
  } else {
    titleText = componentEnhance.jsx(title.text, title.values, `${namespace}-modal`);
  }

  return (
    <div className={namespace}>
      <StyledLabel
        className={`${namespace}__text`}
        as="p"
        color="BLACK"
        font_family="REGULAR"
        font_size={deviceType === 'mobile' ? 'XSMALL' : 'SMALL'}
        text={titleText}
      />
      {feedback && feedback.state === 'VISIBLE' && (
        <Feedback showFeedbackMessage={showFeedbackMessage} feedback={feedback} onLeaveFeedback={onLeaveFeedback} />
      )}
    </div>
  );
};

SmartAnswer.propTypes = {
  feedback: shape({ text: string.isRequired }),
  showFeedbackMessage: bool,
  title: shape({
    text: string.isRequired,
    values: shape({}),
  }),
  onLeaveFeedback: func.isRequired,
};

SmartAnswer.defaultProps = {
  feedback: null,
  showFeedbackMessage: false,
  title: {
    values: null,
  },
};

module.exports = SmartAnswer;
