const cut = require('./cut');
const highlight = require('./highlight');

/**
 * getTools
 * ________________
 * Dado una cantidad máxima de carácteres y highlights se retorna un arreglo que tiene funciones
 * transformadoras (recortar y resaltar)
 *
 * Si se quisiera se podrian agregar más herramientas. Para esto es importante tener en cuenta:
 * 1) El orden de las transformaciones importa.
 * 2) Una transformación tiene que retornar un arrayModel ( Ver utils.js => toArrayModel )
 */

const getTools = ({ maxChars, highlights }) => [
  {
    transform: cut(maxChars),
    validate: () => !!maxChars,
  },
  {
    transform: highlight(highlights),
    validate: () => highlights?.length > 0,
  },
];

module.exports = getTools;
