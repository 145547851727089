const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');

const ICON_ID = 'help';
const namespace = 'ui-pdp-icon ui-pdp-icon--help';

const IconHelp = ({ className }) => (
  <svg className={classnames(namespace, className)} xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 36 34">
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconHelp.propTypes = {
  className: string,
};

IconHelp.defaultProps = {
  className: null,
};

module.exports = React.memo(IconHelp);
exports = module.exports;
exports.ICON_ID = ICON_ID;
