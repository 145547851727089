const {
  hasGrantedNotifactions,
  hasBlockedNotifications,
  isPushNotificationsPermissionModal,
  PUSH_NOTIFICATION_BOOKMARK_MODAL,
  PUSH_NOTIFICATION_QUESTION_MODAL,
  PUSH_NOTIFICATION_DECLINE_ACTION,
} = require('@vpp-frontend-components/common');

module.exports = {
  hasGrantedNotifactions,
  hasBlockedNotifications,
  isPushNotificationsPermissionModal,
  PUSH_NOTIFICATION_BOOKMARK_MODAL,
  PUSH_NOTIFICATION_QUESTION_MODAL,
  PUSH_NOTIFICATION_DECLINE_ACTION,
};
