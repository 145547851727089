// Este componente esta siendo migrado al nuevo repositorio de https://github.com/melisource/fury_vpp-frontend-components, no modificar, ante cualquier duda mandar mensaje a Mcalderon

const { hyphenatedbem } = require('../../lib/hyphenatedbem');
const componentEnhance = require('../../lib/component-enhance');

const labelToProps = props => ({
  ...props.label,
  text: componentEnhance.jsx(props.label.text, props.label.values),
  className: hyphenatedbem(props.id),
  alignment: props.alignment,
  as: 'h2',
});

module.exports = labelToProps;
