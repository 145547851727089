const React = require('react');

const { useState, useContext } = require('react');
const { string, func, shape, bool } = require('prop-types');
const StaticPropsContext = require('../../../context/static-props');

const BaseModal = require('../../../modals/base-modal');
const QuestionForm = require('../question-form/question-form');
const { trackEvent } = require('../../../../lib/tracking');

const namespace = 'ui-pdp-question-modal';

const QuestionModal = ({
  id,
  title,
  subtitle,
  action,
  item_id,
  product_id,
  query,
  disabled,
  parameters,
  onCreateQuestion,
  onCancelSearchQuery,
  app,
  close_modal_label,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const { deviceType } = useContext(StaticPropsContext);
  const type = deviceType === 'mobile' ? 'card' : 'large';
  trackEvent(action.track);

  const handleMakeQuestion = ({ question, failure, success }) => {
    onCreateQuestion({ item_id, question, failure, success, app, parameters, form: id });
    setIsOpen(false);
  };

  const handleClose = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onCancelSearchQuery({ item_id, product_id });
    setIsOpen(false);
  };

  const modalClassname = subtitle ? '' : 'modal-fixed-height';

  return (
    <BaseModal
      key={`${namespace}__modal-${Math.random()}`}
      id={`${namespace}__modal`}
      className={modalClassname}
      isOpen={isOpen}
      onClose={handleClose}
      closable
      closeModalLabel={close_modal_label}
      title={title}
      type={type}
      autoHeight={false}
      maxWidth={640}
    >
      <h4 data-testid="subtitle" className={`${namespace}__subtitle`}>
        {subtitle}
      </h4>
      <QuestionForm
        onCreateQuestion={handleMakeQuestion}
        onCancelSearchQuery={handleClose}
        initialValue={query}
        item_id={item_id}
        product_id={product_id}
        disabled={disabled}
        action={action}
        id={id}
      />
    </BaseModal>
  );
};

QuestionModal.propTypes = {
  app: string,
  id: string.isRequired,
  action: shape({
    text: string.isRequired,
    form: shape({
      title: string,
    }).isRequired,
  }).isRequired,
  parameters: shape({}),
  item_id: string.isRequired,
  product_id: string,
  onCreateQuestion: func.isRequired,
  onCancelSearchQuery: func.isRequired,
  query: string,
  subtitle: string,
  disabled: bool,
  title: string.isRequired,
  close_modal_label: string.isRequired,
};

QuestionModal.defaultProps = {
  app: null,
  product_id: null,
  query: null,
  subtitle: null,
  disabled: false,
  parameters: null,
};

module.exports = QuestionModal;
