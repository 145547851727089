const React = require('react');
const { string, oneOfType, node, func } = require('prop-types');
const { Label } = require('../../prop-types');
const Media = require('../media');

const BoxListItem = ({ className, label, title, figure }) => {
  const content = label || title;

  return (
    content && (
      <li key={content.text}>
        <Media className={className} title={content} figure={figure} />
      </li>
    )
  );
};

BoxListItem.propTypes = {
  className: string,
  label: Label,
  title: Label,
  figure: oneOfType([node, func]),
};

module.exports = BoxListItem;
