const React = require('react');
const { string, shape, func } = require('prop-types');
const Button = require('@andes/button');
const IconHelp = require('../../../icons/help');
const { trackEvent } = require('../../../../lib/tracking');

const { ButtonText } = Button;

const namespace = 'ui-pdp-qadb__how-to-make-question-button';

const HowToMakeQuestionButton = ({ action, tooltip, onClickHowToMakeQuestionButton }) => {
  const handleClick = () => {
    onClickHowToMakeQuestionButton({ tooltip });
    trackEvent(action.track);
  };

  return (
    <Button data-testid="how-to-make-question" className={namespace} hierarchy="quiet" onClick={handleClick}>
      <IconHelp className={`${namespace}__icon`} />
      <ButtonText className={`${namespace}__text`}>{action.label.text}</ButtonText>
    </Button>
  );
};

HowToMakeQuestionButton.propTypes = {
  action: shape({
    label: shape({ text: string.isRequired }).isRequired,
    track: shape({ melidata_event: shape({}).isRequired }).isRequired,
  }).isRequired,
  tooltip: shape({ title: shape({ text: string }), body: shape({ text: string }) }).isRequired,
  onClickHowToMakeQuestionButton: func.isRequired,
};

HowToMakeQuestionButton.defaultProps = {};

module.exports = HowToMakeQuestionButton;
